import './App.css';
import {Layout, Menu, BackTop, Row, Col} from 'antd';
import {HashRouter, Route, Routes} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import React from 'react';
import Home from "./pages/Home";
import {OchranaOsUdajov} from "./pages/OchranaOsUdajov";
import logo from "./right_logo-nobg.png";

const {Header, Content, Footer} = Layout;

export default class App extends React.Component {
    homePageRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            selectedMenuItem: null,
            headerClass: "header",
            menuItemClass: "menu-item",
        }
    }

    nearestIndex(currentPosition, sectionPositionArray, startIndex, endIndex) {
        if (startIndex === endIndex) return startIndex;
        else if (startIndex === endIndex - 1) {
            if (
                Math.abs(
                    sectionPositionArray[startIndex].headerRef.current.offsetTop -
                    currentPosition
                ) <
                Math.abs(
                    sectionPositionArray[endIndex].headerRef.current.offsetTop -
                    currentPosition
                )
            )
                return startIndex;
            else return endIndex;
        } else {
            var nextNearest = ~~((startIndex + endIndex) / 2);
            var a = Math.abs(
                sectionPositionArray[nextNearest].headerRef.current.offsetTop -
                currentPosition
            );
            var b = Math.abs(
                sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop -
                currentPosition
            );
            if (a < b) {
                return this.nearestIndex(currentPosition, sectionPositionArray, startIndex, nextNearest);
            } else {
                return this.nearestIndex(currentPosition, sectionPositionArray, nextNearest, endIndex);
            }
        }
    };

    render() {
        const changeBackground = () => {
            if (window.scrollY > 0 && this.homePageRef.current) {
                this.setState({
                    headerClass: "header active",
                    menuItemClass: "menu-item-active",
                    selectedMenuItem: this.homePageRef.current.state.refs[this.nearestIndex(window.scrollY, this.homePageRef.current.state.refs, 0, 3)].key
                });

            } else {
                this.setState({
                    headerClass: "header",
                    menuItemClass: "menu-item",
                    selectedMenuItem: null,
                });
            }
        };

        window.addEventListener('scroll', changeBackground);

        return (
            <div className="App">

                <HashRouter>
                    <Layout className="layout">
                        <Header className={this.state.headerClass}>
                            <Row>
                                <Col xs={{span: 20, order: 2}} lg={{span: 8, order: 1}} style={{paddingLeft: "50px", textAlign: "left",}}>
                                    <HashLink to="/" onClick={() => {
                                        this.setState({selectedMenuItem: 'home'});
                                    }}>
                                        <h3>DataInsight</h3>
                                    </HashLink>
                                </Col>
                                <Col xs={{span: 4, order: 1}} lg={{span: 16, order: 2}}>
                                    <Menu
                                        className="menu-bar"
                                        selectedKeys={this.state.selectedMenuItem}
                                        onClick={(item) => {
                                            this.setState({selectedMenuItem: item.key});
                                        }}
                                        /*expandIcon={<img src={logo} alt="logo"/>}*/
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            <img src={logo} alt="logo"/>
                                        }
                                        mode="horizontal"
                                        items={[
                                            {
                                                label: (
                                                    <HashLink to="/#home" id={this.state.menuItemClass}>
                                                        Domov
                                                    </HashLink>
                                                ),
                                                key: 'home',
                                            },
                                            {
                                                label: (
                                                    <HashLink to="/#about" id={this.state.menuItemClass}>
                                                        Analýza
                                                    </HashLink>
                                                ),
                                                key: 'about',
                                            },
                                            {
                                                label: (
                                                    <HashLink to="/#faq" id={this.state.menuItemClass}>
                                                        FAQ
                                                    </HashLink>
                                                ),
                                                key: 'faq',
                                            },
                                            {
                                                label: (
                                                    <HashLink to="/#contact" id={this.state.menuItemClass}>
                                                        Kontakt
                                                    </HashLink>
                                                ),
                                                key: 'contact',
                                            }
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Header>
                        <Content>
                            <Routes>
                                <Route path="/" element={<Home ref={this.homePageRef}/>}/>
                                <Route path="/ochrana-osobnych-udajov" element={<OchranaOsUdajov/>}/>
                            </Routes>
                            <BackTop/>
                        </Content>
                        <Footer>
                            <Row>
                                <Col span={12} style={{textAlign: "right", paddingRight: "50px"}}>
                                    <HashLink to='/ochrana-osobnych-udajov' style={{color: "var(--secondColor)"}}>Ochrana
                                        osobných údajov</HashLink>
                                </Col>
                                <Col span={12} style={{textAlign: "left", paddingLeft: "50px", color: "var(--secondColor)"}}>
                                    ©2022 Created by Tomka & Oleš
                                </Col>
                            </Row>
                        </Footer>
                    </Layout>
                </HashRouter>
            </div>
        );
    }
}
