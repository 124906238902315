import React from 'react';

export class OchranaOsUdajov extends React.Component {
    render() {
        return (
            <div className="content" style={{textAlign: "left"}}>
                <h1>
                    Ochrana osobných údajov
                </h1>
                <h3>
                    Oboznámenie so spracúvaním osobných údajov
                </h3>
                <p>
                    Podľa článku 13 NARIADENIA EURÓPSKEHO PARLAMENTU A RADY (EÚ) 2016/679 z 27. apríla 2016 o
                    ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov a
                    zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov ako
                    prevádzkovateľ na základe zmluvy.
                </p>
                <h3>
                    A. Identifikačné údaje prevádzkovateľa:
                </h3>
                <p>
                    Prevádzkovateľ Apperra, s.r.o., so sídlom Odbojárska 640/32, 018 51 Nová Dubnica, Slovensko,
                    IČO: 54404711 (ďalej len „Prevádzkovateľ“).
                </p>
                <h3>
                    B. Účel spracúvania osobných údajov prevádzkovateľom:
                </h3>
                <p>
                    Vaše osobné údaje použijeme iba vtedy, keď nám to zákon dovoľuje. Najčastejšie použijeme
                    vaše osobné údaje za nasledujúcich okolností:
                </p>
                <p>
                    <b>ak potrebujeme vykonať zmluvu, do ktorej sa chystáme vstúpiť alebo do ktorej sme s vami
                        vstúpili,</b>
                </p>
                <p>
                    <b>ak je pre naše oprávnené záujmy potrebné vylepšiť naše služby:</b>
                </p>
                <p>
                    Oprávneným záujmom prevádzkovateľa je právo podnikať v rozsahu predmetu jeho činnosti.
                    Lehota uchovávania osobných údajov je determinovaná prípravou a trvaním obchodnoprávneho
                    vzťahu, ako aj obdobím 2 rokov od ukončenia tohto obchodnoprávneho vzťahu. Príjemcami
                    osobných údajov sú spoločnosti vykonávajúce správu a podporu informačných technológií,
                    subjekty zabezpečujúce výkon externého auditu, poskytovatelia telekomunikačných služieb,
                    poskytovatelia dátových úložísk a v odôvodnených prípadoch aj súdy a orgány činné v
                    trestnom konaní; a za účelom marketingu, príp. zasielania newslettera.
                </p>
                <p>
                    Oprávneným záujmom [čl. 6 ods. 1 písm. f) Nariadenia] a vaším oprávneným záujmom je
                    možnosť v budúcnosti vás zoznámiť s najnovšími ponukami služieb, ktoré môžu byť pre
                    vás výhodnejšie než existujúce služby. Poskytované služby sa časom menia a my by sme boli
                    radi, keby ste vždy mali možnosť vybrať si tie, ktoré najlepšie vyhovujú vašim potrebám.
                </p>
                <p>
                    <b>ak potrebujeme dodržiavať zákonné alebo regulačné povinnosti.</b>
                </p>
                <p>
                    Za určitých okolností môžeme Vaše osobné údaje spracovať aj na základe vášho súhlasu.
                    Ak tak urobíme, oznámime vám účel a kategóriu osobných údajov, ktoré sa majú spracovať v
                    čase, keď žiadame váš súhlas. Spracúvame údaje aj na základe cookies.
                </p>
                <h5>
                    Cookies a podobné technológie
                </h5>
                <p>
                    Súbory cookies používame na spravovanie relácií našich používateľov, na ukladanie výberu
                    preferencií jazykov. Súbory cookies sú malé textové súbory prenášané webovým serverom na
                    pevný disk vášho zariadenia. Súbory cookies sa môžu používať na získavanie dátumu a času
                    vašej návštevy, histórie prehliadania, vašich preferencií a vášho
                    používateľského mena. Môžete nastaviť prehliadač tak, aby odmietol všetky alebo niektoré
                    súbory cookies, alebo aby Vás upozornil, keď webové stránky majú nastavenia alebo prístup k
                    súborom cookies. Vezmite, prosím, na vedomie, že ak zakážete alebo odmietnete používať
                    súbory cookies, niektoré časti našej služby/platformy sa môžu stať nedostupnými alebo
                    nebudú fungovať správne.
                </p>
                <h3>
                    C. Zoznam osobných údajov:
                </h3>
                <p>
                    Meno
                    Priezvisko E-mailová adresa Telefónne číslo
                </p>
                <h3>
                    D. Kontrola
                </h3>
                <p>
                    Kontrolu nad dodržiavaním ochrany osobných údajov u nás zabezpečuje zodpovedná osoba, ktorú
                    môžete kontaktovať prostredníctvom e-mailu: privacy@apperra.sk alebo písomne prostredníctvom
                    listu zaslaného na adresu sídla spoločnosti Apperra, s.r.o. Odbojárska 640/32, 018 51 Nová
                    Dubnica, s označením: „do rúk zodpovednej osoby“.
                </p>
                <h3>
                    E. S kým zdieľame vaše údaje?
                </h3>
                <h5>
                    Sprostredkovatelia:
                </h5>
                <p>
                    Vykonávame kontroly svojich sprostredkovateľov a požadujeme, aby rešpektovali bezpečnosť
                    vašich osobných údajov a aby sa s nimi zaobchádzalo v súlade so zákonom. Nedovoľujeme im, aby
                    používali vaše osobné údaje na vlastné účely, a povoľujeme im len spracovanie vašich
                    osobných údajov na určené účely a v súlade s našimi pokynmi.
                </p>
                <h5>
                    Orgány činné v trestnom konaní, regulačné orgány a iné:
                </h5>
                <p>
                    Vaše osobné údaje môžeme zverejniť orgánom činným v trestnom konaní, regulačným
                    orgánom, vládnym alebo verejným orgánom a iným príslušným tretím stranám, aby sme splnili
                    všetky zákonné alebo regulačné požiadavky.
                </p>
                <h3>
                    F. Doplňujúce informácie:
                </h3>
                <p>
                    1. Súhlas dotknutej osoby v prípade marketingových aktivít alebo získavania údajov.
                </p>
                <p>
                    2. Osobné údaje nebudú poskytnuté tretím stranám.
                </p>
                <p>
                    3. Prevádzkovateľ vyhlasuje, že pri výbere sprostredkovateľov dbá na ich odbornú, technickú,
                    organizačnú a personálnu spôsobilosť a ich schopnosť zaručiť bezpečnosť
                    spracúvaných osobných údajov opatreniami podľa § 39 zákona č. 18/2018 Z. z.
                </p>
                <p>
                    4. Prevádzkovateľ vyhlasuje, že prijal všetky opatrenia v zmysle § 39 zákona č. 18/2018 Z. z.
                    a týmto sa zaväzuje chrániť tieto údaje pred náhodným, ako aj nezákonným poškodením a
                    zničením, náhodnou stratou, zmenou, nedovoleným prístupom a sprístupnením, ako aj pred
                    akýmikoľvek inými neprípustnými formami spracúvania v zmysle opatrení prijatých v
                    bezpečnostnej smernici.
                </p>
                <p>
                    5. Sprostredkovateľ sa zaväzuje spracúvať osobné údaje len v rozsahu a za podmienok
                    dohodnutých v poverení sprostredkovateľa spracovaním osobných údajov.
                </p>
                <p>
                    6. Prevádzkovateľ vyhlasuje, že nebude poskytovať osobné údaje iným Sprostredkovateľom ako
                    tým, ktorí sú uvedení v tomto oboznámení.
                </p>
                <p>
                    7. Prevádzkovateľ vyhlasuje, že bude zhromažďovať osobné údaje v rozsahu nevyhnutnom na
                    naplnenie stanoveného účelu a spracúvať ich len v súlade s účelom, na ktorý boli
                    zhromaždené.
                </p>
                <p>
                    8. Prevádzkovateľ je povinný zachovávať mlčanlivosť o osobných údajoch, ktoré spracúva.
                    Povinnosť mlčanlivosti trvá aj po ukončení spracúvania osobných údajov.
                </p>
                <h3>
                    G. Poučenie o právach dotknutej osoby:
                </h3>
                <h5>
                    Právo na prístup
                </h5>
                <p>
                    1. Dotknutá osoba má právo získať od prevádzkovateľa potvrdenie o tom, či sa spracúvajú
                    osobné údaje, ktoré sa jej týkajú, a ak to tak je, má právo získať prístup k týmto
                    osobným údajom a informáciám: a) účely spracúvania; b) kategórie dotknutých osobných
                    údajov; c) príjemcovia alebo kategórie príjemcov, ktorým boli alebo budú osobné údaje
                    poskytnuté, najmä príjemcovia v tretích krajinách alebo medzinárodné organizácie; d) ak je
                    to možné, predpokladané obdobie uchovávania osobných údajov alebo, ak to nie je možné,
                    kritériá na jeho určenie; e) existencia práva požadovať od prevádzkovateľa opravu osobných
                    údajov týkajúcich sa dotknutej osoby alebo ich vymazanie, alebo obmedzenie spracúvania, alebo
                    práva namietať proti takémuto spracúvaniu; f) právo podať sťažnosť dozornému orgánu; g)
                    ak sa osobné údaje nezískali od dotknutej osoby, akékoľvek dostupné informácie, pokiaľ ide o
                    ich zdroj; h) existencia automatizovaného rozhodovania vrátane profilovania uvedeného v
                    článku 22 ods. 1 a 4 Nariadenia a v týchto prípadoch aspoň zmysluplné informácie o použitom
                    postupe, ako aj význame a predpokladaných dôsledkoch takéhoto spracúvania pre dotknutú osobu.
                </p>
                <p>
                    2. Ak sa osobné údaje prenášajú do tretej krajiny alebo v medzinárodnej organizácii,
                    dotknutá osoba má právo byť informovaná o primeraných zárukách podľa článku 46 Nariadenia
                    týkajúcich sa prenosu.
                </p>
                <p>
                    3. Prevádzkovateľ poskytne kópiu osobných údajov, ktoré sa spracúvajú. Za akékoľvek
                    ďalšie kópie, o ktoré dotknutá osoba požiada, môže prevádzkovateľ účtovať primeraný
                    poplatok zodpovedajúci administratívnym nákladom. Ak dotknutá osoba podala žiadosť
                    elektronickými prostriedkami, informácie sa poskytnú v bežne používanej elektronickej podobe,
                    ak
                    dotknutá osoba nepožiadala o iný spôsob.
                </p>
                <p>
                    4. Právo získať kópiu uvedenú v odseku 3 nesmie mať nepriaznivé dôsledky na práva a slobody
                    iných.
                </p>
                <h5>
                    Právo na opravu
                </h5>
                <p>
                    Dotknutá osoba má právo na to, aby prevádzkovateľ bez zbytočného odkladu opravil nesprávne
                    osobné údaje, ktoré sa jej týkajú. So zreteľom na účely spracúvania má dotknutá osoba
                    právo na doplnenie neúplných osobných údajov, a to aj prostredníctvom poskytnutia doplnkového
                    vyhlásenia.
                </p>
                <h5>
                    Právo na vymazanie („zabudnutie“)
                </h5>
                <p>
                    1. Dotknutá osoba má aj právo dosiahnuť u prevádzkovateľa bez zbytočného odkladu vymazanie
                    osobných údajov, ktoré sa jej týkajú, a prevádzkovateľ je povinný bez zbytočného odkladu
                    vymazať osobné údaje, ak je splnený niektorý z týchto dôvodov: a) osobné údaje už nie sú
                    potrebné na účely, na ktoré sa získavali alebo inak spracúvali; b) dotknutá osoba odvolá
                    súhlas, na základe ktorého sa spracúvanie vykonáva, podľa článku 6 ods. 1
                    písm. a) Nariadenia alebo článku 9 ods. 2 písm. a) Nariadenia, a ak neexistuje iný právny
                    základ na spracúvanie; c) dotknutá osoba namieta proti spracúvaniu podľa článku 21 ods. 1 a
                    neprevažujú žiadne oprávnené dôvody na spracúvanie alebo dotknutá osoba namieta proti
                    spracúvaniu podľa článku 21 ods. 2 Nariadenia; d) osobné údaje sa spracúvali nezákonne; e)
                    osobné údaje musia byť vymazané, aby sa splnila zákonná povinnosť podľa práva Únie alebo
                    práva členského štátu, ktorému prevádzkovateľ podlieha; f) osobné údaje sa získavali v
                    súvislosti s ponukou služieb informačnej spoločnosti podľa článku 8 ods. 1 Nariadenia.
                </p>
                <p>
                    2. Ak prevádzkovateľ zverejnil osobné údaje a podľa odseku 1 je povinný vymazať osobné
                    údaje, so zreteľom na dostupnú technológiu a náklady na vykonanie opatrení, podnikne
                    primerané opatrenia vrátane technických opatrení, aby informoval prevádzkovateľov, ktorí
                    vykonávajú spracúvanie osobných údajov, že dotknutá osoba ich žiada, aby vymazali všetky
                    odkazy na tieto osobné údaje, ich kópiu alebo repliky.
                </p>
                <p>
                    3. Odseky 1 a 2 sa neuplatňujú, ak je spracúvanie potrebné: a) na uplatnenie práva na slobodu
                    prejavu a na informácie; b) na splnenie zákonnej povinnosti, ktorá si vyžaduje spracúvanie
                    podľa práva Únie alebo práva členského štátu, ktorému prevádzkovateľ podlieha, alebo na
                    splnenie úlohy realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej
                    prevádzkovateľovi; c) z dôvodov verejného záujmu v oblasti verejného zdravia v súlade s
                    článkom 9 ods. 2 písm. h) a i) Nariadenia, ako aj s článkom 9 ods. 3 Nariadenia; d) na účely
                    archivácie vo verejnom záujme, na účely vedeckého alebo historického výskumu či na
                    štatistické účely podľa článku 89 ods. 1 Nariadenia, ak je pravdepodobné, že právo
                    uvedené v odseku 1 znemožní alebo závažným spôsobom sťaží dosiahnutie cieľov takéhoto
                    spracúvania, alebo e) na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov.
                </p>
                <h5>
                    Právo na obmedzenie spracúvania
                </h5>
                <p>
                    1. Dotknutá osoba má právo na to, aby prevádzkovateľ obmedzil spracúvanie, pokiaľ ide o jeden
                    z týchto prípadov:
                </p>
                <p>
                    a) dotknutá osoba napadne správnosť osobných údajov, a to počas obdobia umožňujúceho
                    prevádzkovateľovi overiť správnosť osobných údajov;
                </p>
                <p>
                    b) spracúvanie je protizákonné a dotknutá osoba namieta proti vymazaniu osobných údajov a
                    žiada namiesto toho obmedzenie ich použitia;
                </p>
                <p>
                    c) prevádzkovateľ už nepotrebuje osobné údaje na účely spracúvania, ale potrebuje ich
                    dotknutá osoba na preukázanie, uplatňovanie alebo obhajovanie právnych nárokov;
                </p>
                <p>
                    d) dotknutá osoba namietala proti spracúvaniu podľa článku 21 ods. 1 Nariadenia, a to až do
                    overenia, či oprávnené dôvody na strane prevádzkovateľa prevažujú nad oprávnenými dôvodmi
                    dotknutej osoby.
                </p>
                <p>
                    2. Ak sa spracúvanie obmedzilo podľa odseku 1, takéto osobné údaje sa s výnimkou uchovávania
                    spracúvajú len so súhlasom dotknutej osoby alebo na preukazovanie, uplatňovanie alebo
                    obhajovanie právnych nárokov, alebo na ochranu práv inej fyzickej alebo právnickej osoby, alebo
                    z dôvodov dôležitého verejného záujmu Únie alebo členského štátu.
                </p>
                <p>
                    3. Dotknutú osobu, ktorá dosiahla obmedzenie spracúvania podľa odseku 1, prevádzkovateľ
                    informuje predtým, ako bude obmedzenie spracúvania zrušené.
                </p>
                <h5>
                    Právo na prenosnosť
                </h5>
                <p>
                    1. Dotknutá osoba má právo získať osobné údaje, ktoré sa jej týkajú a ktoré poskytla
                    prevádzkovateľovi, v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte a má
                    právo preniesť tieto údaje ďalšiemu prevádzkovateľovi bez toho, aby jej prevádzkovateľ,
                    ktorému sa tieto osobné údaje poskytli, bránil, ak: a) sa spracúvanie zakladá na súhlase
                    podľa článku 6 ods. 1 písm. a) Nariadenia alebo článku 9 ods. 2 písm. a) Nariadenia alebo na
                    zmluve podľa článku 6 ods. 1 písm. b) Nariadenia, a b) ak sa spracúvanie vykonáva
                    automatizovanými prostriedkami.
                </p>
                <p>
                    2. Dotknutá osoba má pri uplatňovaní svojho práva na prenosnosť údajov podľa odseku 1 právo
                    na prenos osobných údajov priamo od jedného prevádzkovateľa druhému prevádzkovateľovi, ak
                    je to technicky možné.
                </p>
                <p>
                    3. Uplatňovaním práva uvedeného v odseku 1 tohto článku nie je dotknutý článok 17
                    Nariadenia. Uvedené právo sa nevzťahuje na spracúvanie nevyhnutné na splnenie úlohy
                    realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej prevádzkovateľovi.
                </p>
                <p>
                    4. Právo uvedené v odseku 1 nesmie mať nepriaznivé dôsledky na práva a slobody iných.
                </p>
                <h5>
                    Právo namietať
                </h5>
                <p>
                    1. Dotknutá osoba má právo kedykoľvek namietať z dôvodov týkajúcich sa jej konkrétnej
                    situácie proti spracúvaniu osobných údajov, ktoré sa jej týka, ktoré je vykonávané na
                    základe článku 6 ods. 1 písm. e) alebo f) Nariadenia vrátane namietania proti profilovaniu
                    založenému na uvedených ustanoveniach. Prevádzkovateľ nesmie ďalej spracúvať osobné údaje,
                    ak nepreukáže nevyhnutné oprávnené dôvody na spracúvanie, ktoré prevažujú nad záujmami,
                    právami a slobodami dotknutej osoby, alebo dôvody na preukazovanie, uplatňovanie alebo
                    obhajovanie právnych nárokov.
                </p>
                <p>
                    2. Ak sa osobné údaje spracúvajú na účely priameho marketingu, dotknutá osoba má právo
                    kedykoľvek namietať proti spracúvaniu osobných údajov, ktoré sa jej týka, na účely
                    takéhoto marketingu vrátane profilovania v rozsahu, v akom súvisí s takýmto priamym
                    marketingom.
                </p>
                <p>
                    3. Ak dotknutá osoba namieta proti spracúvaniu na účely priameho marketingu, osobné údaje sa
                    už na také účely nesmú spracúvať.
                </p>
                <p>
                    4. Dotknutá osoba sa výslovne upozorní na právo uvedené v ods. 1 a 2 najneskôr pri prvej
                    komunikácii s ňou, pričom sa toto právo prezentuje jasne a oddelene od akýchkoľvek iných
                    informácií.
                </p>
                <p>
                    5. V súvislosti s používaním služieb informačnej spoločnosti a bez ohľadu na smernicu
                    2002/58/ES môže dotknutá osoba uplatňovať svoje právo namietať automatizovanými
                    prostriedkami s použitím technických špecifikácií.
                </p>
                <p>
                    6. Ak sa osobné údaje spracúvajú na účely vedeckého alebo historického výskumu či na
                    štatistické účely podľa čl. 89 ods. 1 Nariadenia, dotknutá osoba má právo namietať z
                    dôvodov týkajúcich sa jej konkrétnej situácie proti spracúvaniu osobných údajov, ktoré sa
                    jej týka, s výnimkou prípadov, keď je spracúvanie nevyhnutné na plnenie úlohy z dôvodov
                    verejného záujmu.
                </p>
                <h5>
                    Oznámenie tretím stranám
                </h5>
                <p>
                    Prevádzkovateľ oznámi každému príjemcovi, ktorému boli osobné údaje poskytnuté, každú
                    opravu alebo vymazanie osobných údajov alebo obmedzenie spracúvania uskutočnené podľa článku
                    16, článku 17 ods. 1 a článku 18 Nariadenia, ak sa to neukáže ako nemožné alebo si to
                    nevyžaduje neprimerané úsilie. Prevádzkovateľ o týchto príjemcoch informuje dotknutú osobu,
                    ak to dotknutá osoba požaduje.
                </p>
                <p>
                    Vaše osobné údaje spracúvame v rámci plnenia povinností stanovených zákonom alebo za
                    účelom marketingu. Pri spracúvaní osobných údajov ste dotknutou osobou, t. j. osobou, o ktorej
                    sú spracúvané osobné údaje, ktoré sa jej týkajú. Zásady ochrany osobných údajov hovoria o
                    tom, že vaše osobné údaje budú uchovávané bezpečne, v súlade s bezpečnostnou politikou
                    spoločnosťou exe, a.s., a len po obdobie nevyhnutné na splnenie účelu spracúvania. Prístup k
                    vašim osobným údajom budú mať výlučne osoby poverené spoločnosťou exe,
                    a.s.(prevádzkovateľom) na spracúvanie osobných údajov, ktoré ich spracúvajú na základe
                    pokynov prevádzkovateľa, v súlade s bezpečnostnou politikou prevádzkovateľa. Vaše osobné
                    údaje budú zálohované v súlade s retenčnými pravidlami spoločnosti exe, a.s. Zo zálohových
                    úložísk budú vaše osobné údaje úplne vymazané hneď, ako to bude možné. Osobné údaje
                    uchovávané na záložných úložiskách slúžia na predchádzanie bezpečnostným incidentom,
                    najmä narušeniu dostupnosti údajov v dôsledku bezpečnostného incidentu. Spoločnosť exe,
                    a.s., je povinná zabezpečovať zálohovanie údajov v súlade s bezpečnostnými požiadavkami
                    GDPR a zákona č. 18/2018 Z. z. a požiadavkami spoločnosti Microsoft.
                </p>
                <h5>
                    Na základe čoho sa budú vaše údaje spracúvať?
                </h5>
                <p>
                    Vaše osobné údaje sa budú spracúvať na základe osobitných právnych predpisov a účelov,
                    ktoré sú stanovené prevádzkovateľom.
                </p>
                <h5>
                    Budú vaše osobné údaje poskytnuté mimo Európskej únie?
                </h5>
                <p>
                    Áno, no len ak ste priamym klientom divízie prekladov a lokalizácií spoločnosti exe. V
                    prípade, že poskytnete osobné údaje za účelom prekladu do jazyka nečlenského štátu
                    Európskej
                    únie, môžu sa vaše údaje spracúvať aj mimo EÚ.
                </p>
                <h5>
                    Použijú sa vaše údaje na automatizované individuálne rozhodovanie?
                </h5>
                <p>
                    Osobné údaje sa nepoužijú na automatizované individuálne rozhodovanie vrátane profilovania.
                </p>
                <h5>
                    Ako dlho sa budú vaše osobné údaje uchovávať?
                </h5>
                <p>
                    Na uchovávanie osobných údajov, ktoré o vás spracúvame, sa vzťahuje zákon č. 395/2002 Z. z.
                    o archívoch a registratúrach v spojení s registratúrnym plánom spoločnosti exe, a.s., ktorý
                    je schválený.
                </p>
                <h5>
                    Aké máte práva?
                </h5>
                <p>
                    Právo odvolať súhlas – v prípadoch, keď vaše osobné údaje spracúvame na základe vášho
                    súhlasu, máte právo tento súhlas kedykoľvek odvolať. Súhlas môžete odvolať elektronicky,
                    na adrese zodpovednej osoby, písomne, oznámením o odvolaní súhlasu alebo osobne v sídle našej
                    spoločnosti. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov, ktoré
                    sme na jeho základe o vás spracúvali.
                </p>
                <p>
                    Právo na prístup – máte právo na poskytnutie kópie osobných údajov, ktoré o vás máme k
                    dispozícii, ako aj na informácie o tom, ako vaše osobné údaje používame. Vo väčšine
                    prípadov vám budú vaše osobné údaje poskytnuté v písomnej listinnej forme, ak nepožadujete
                    iný spôsob ich poskytnutia. Ak ste o poskytnutie týchto informácií požiadali
                    elektronickými prostriedkami, budú vám poskytnuté elektronicky, ak to bude technicky možné.
                    Prístup k vašim osobným údajom budú mať výlučne osoby poverené spoločnosťou exe,
                    a.s.(prevádzkovateľom) na spracúvanie osobných údajov, ktoré ich spracúvajú na základe
                    pokynov prevádzkovateľa, v súlade s bezpečnostnou politikou prevádzkovateľa. Vaše osobné
                    údaje budú zálohované v súlade s retenčnými pravidlami spoločnosti exe, a.s. Zo zálohových
                    úložísk budú vaše osobné údaje úplne vymazané, hneď ako to bude možné v súlade s
                    pravidlami zálohovania.
                </p>
                <p>
                    Právo na opravu – prijímame primerané opatrenia, aby sme zabezpečili presnosť, úplnosť a
                    aktuálnosť informácií, ktoré o vás máme k dispozícii. Ak si myslíte, že údaje, ktorými
                    disponujeme, sú nepresné, neúplné alebo neaktuálne, neváhajte nás požiadať, aby sme tieto
                    informácie upravili, aktualizovali alebo doplnili.
                </p>
                <p>Právo na výmaz (na zabudnutie) – máte právo požiadať nás o vymazanie svojich osobných
                    údajov, napríklad v prípade, ak osobné údaje, ktoré sme o vás získali, už viac nie sú
                    potrebné na naplnenie pôvodného účelu spracúvania. Vaše právo je však potrebné posúdiť z
                    pohľadu všetkých relevantných okolností. Napríklad môžeme mať určité právne a
                    regulačné povinnosti, čo znamená, že nebudeme môcť vašej žiadosti vyhovieť.
                </p>
                <p>
                    Právo na obmedzenie spracúvania – za určitých okolností ste oprávnení nás požiadať, aby
                    sme prestali používať vaše osobné údaje. Ide napríklad o prípady, keď si myslíte, že
                    osobné údaje, ktoré o vás máme, môžu byť nepresné, alebo keď si myslíte, že vaše
                    osobné údaje už nepotrebujeme využívať.
                </p>
                <p>
                    Právo na prenosnosť údajov – za určitých okolností máte právo požiadať nás o prenos
                    osobných údajov, ktoré ste nám poskytli, na inú tretiu stranu podľa svojho výberu. Právo na
                    prenosnosť sa však týka len osobných údajov, ktoré sme od vás získali na základe súhlasu
                    alebo na základe zmluvy, v ktorej stejednou zo zmluvných strán.
                </p>
                <p>
                    Právo namietať – máte právo namietať proti spracúvaniu údajov, ktoré je založené na
                    našich legitímnych oprávnených záujmoch. V prípade, že nemáme presvedčivý legitímny
                    oprávnený dôvod na spracúvanie a vy podáte námietku, nebudeme vaše osobné údaje ďalej
                    spracúvať.
                </p>
                <p>
                    Právo podať návrh na začatie konania o ochrane osobných údajov - ak sa domnievate, že vaše
                    osobné údaje spracúvame nespravodlivo alebo nezákonne, môžete podať sťažnosť na dozorný
                    orgán, ktorým je Úrad na ochranu osobných údajov Slovenskej republiky, Hraničná 12, 820 07
                    Bratislava 27; tel. číslo: +421/2/32 31 32 14; e-mail: statny. dozor@pdp.gov.sk,
                    https://dataprotection.gov.sk. V prípade podania návrhu elektronickou formou je potrebné, aby
                    spĺňal náležitosti podľa § 19 ods. 1 zákona č. 71/1.
                </p>
                <p>
                    <i>Aperra, s.r.o.
                        Odbojárska 640/32
                        018 51 Nová Dubnica
                        Spoločnosť zapísaná v obchodnom registri
                        Okresného súdu Trenčín, oddiel: Sro, vložka č. 43345/R
                        Telefón +421 944 645 000 E-mail apperra@apperra.sk Web www.apperra.sk
                        DIČ 2121688272 IČO 54404711
                        IBAN SK14 0900 0000 0051 8857 6214</i>
                </p>
            </div>
        );
    }
}