import {BackTop, Row, Col, Card, Collapse, Form, Input, Button, Steps} from 'antd';
import React from 'react';
import '../App.css';
//import logo from '../Big-Data-Analysis_1.png.png';
import logo from '../right_logo-nobg.png';
import {
    ContainerFilled,
    EditFilled,
    FacebookFilled,
    FundFilled,
    LinkedinFilled,
    PieChartFilled
} from '@ant-design/icons';

const {Panel} = Collapse;
const {TextArea} = Input;
const {Step} = Steps;

export default class Home extends React.Component {

    homeRef = React.createRef();
    aboutRef = React.createRef();
    faqRef = React.createRef();
    contactRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            refs: [
                {
                    headerRef: this.homeRef,
                    key: 'home',
                },
                {
                    headerRef: this.aboutRef,
                    key: 'about',
                },
                {
                    headerRef: this.faqRef,
                    key: 'faq',
                },
                {
                    headerRef: this.contactRef,
                    key: 'contact',
                },
            ],
        }
    }

    render() {
        return (
            <div className="App">
                <div id="home" ref={this.homeRef}>
                    <Row>
                        <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="home-text">
                            <h1 style={{
                                textAlign: "left",
                                // color: "#d9b196"
                            }}>Spoľahlivá dátová analytika pre Vaše podnikanie </h1>
                            <p id="intro-text">
                                Pomôžeme Vám zorientovať sa vo firemných dátach. Podrobná dátová analytika Vám ukáže
                                možnosti, ako ušetriť peniaze, znížiť riziká a efektívne využiť nové zdroje príjmov.
                                Vaše podnikanie tak bude rýchlejšie napredovať a ľahšie dosiahnete stanovené ciele.
                            </p>
                        </Col>
                        <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}}>
                            <img src={logo} className="app-logo" alt="logo"/>
                        </Col>
                    </Row>
                    <Row className="faq-collapse">
                        <Row gutter={16}>
                            <Col xs={{span: 24}} lg={{span: 6}}>
                                <Card title={<div><EditFilled id="card-icon"/> <h4>Efektívnejšie riadenie</h4></div>}
                                      id="card-benefits"
                                      bordered={false}
                                      hoverable={false}
                                >
                                    <p className="card-text">Dátová analytika Vám pomôže nájsť nedokonalosti v riadení
                                        vášho podnikania. Na základe zistení dokážete optimalizovať svoje procesy, aby
                                        fungovali efektívnejšie.</p>
                                </Card>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 6}}>
                                <Card
                                    title={<div><ContainerFilled id="card-icon"/> <h4>Rýchlejšie rozhodnutia</h4></div>}
                                    id="card-benefits"
                                    bordered={false}
                                    hoverable={false}
                                >
                                    <p className="card-text">Vďaka presným dátam už nebudete robiť rozhodnutia metódou
                                        pokus-omyl. Dokážete sa flexibilne prispôsobovať rôznym situáciám a viete
                                        promptne reagovať na nové príležitosti.</p>
                                </Card>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 6}}>
                                <Card title={<div><PieChartFilled id="card-icon"/> <h4>Minimalizácia rizík</h4></div>}
                                      id="card-benefits"
                                      bordered={false}
                                      hoverable={false}
                                >
                                    <p className="card-text">Analýza dát Vám umožní ľahšie porozumieť rizikám vášho
                                        podnikania. Vďaka presným údajom ich viete včas odhaliť a môžete vykonať vhodné
                                        opatrenia na ich minimalizáciu.</p>
                                </Card>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 6}}>
                                <Card title={<div><FundFilled id="card-icon"/> <h4>Nové zdroje príjmov</h4></div>}
                                      id="card-benefits"
                                      bordered={false}
                                      hoverable={false}
                                >
                                    <p className="card-text">Ktoré príležitosti sa oplatí využiť a ktoré pre Vás nie sú
                                        výhodné? Dáta Vám ukážu tú správnu cestu k vyšším tržbám a pomôžu odhaliť diery
                                        na trhu s vysokým potenciálom.</p>
                                </Card>
                            </Col>
                        </Row>
                    </Row>
                </div>
                <div id="about" ref={this.aboutRef}>
                    <h1>Ako prebieha dátová analytika?</h1>
                    <Row className="faq-collapse" style={{marginTop: "200px"}}>
                        <Col xs={{span: 24}} lg={{span: 12, offset: 12}}>
                            <Steps direction="vertical">
                                <Step status={"process"} title={
                                    <Card hoverable={true} className="card-steps">
                                        <h2><EditFilled id="card-icon"/> Stanovenie cieľov</h2>
                                        <p style={{color: "var(--mainColor)"}}>Na začiatku spolupráce si stanovíme ciele
                                            - čo vlastne chcete vyhodnocovať. Napríklad, ktoré produkty sa najlepšie
                                            predávajú, v akej lokalite, ktorý predajca z vášho tímu je najúspešnejší
                                            alebo čo Vám v podnikaní prináša najlepšie výsledky. Podľa stanovených
                                            cieľov naplánujeme ďalší postup.
                                        </p>
                                    </Card>
                                }/>
                                <Step status={"process"} title={
                                    <Card hoverable={true} className="card-steps" id="left-card">
                                        <h2><ContainerFilled id="card-icon"/> Zber dát</h2>
                                        <p style={{color: "var(--mainColor)"}}>Na základe vytýčených cieľov zozbierame
                                            relevantné dáta, ktoré si sami spracujeme. Súčasťou tohto kroku je tzv. data
                                            mining (získavanie dát) a data cleansing – čistenie dát do ľahko
                                            spracovateľnej podoby, s ktorou sa dá ďalej pracovať. Vyhodnotíme, na čo sa
                                            zamerať, aby sme získali správne výsledky.</p>
                                    </Card>
                                }/>
                                <Step status={"process"} title={
                                    <Card hoverable={true} className="card-steps">
                                        <h2><PieChartFilled id="card-icon"/> Analýza zozbieraných dát</h2>
                                        <p style={{color: "var(--mainColor)"}}>Očistené dáta filtrujeme, podrobíme
                                            štatistickým výpočtom (zistíme korelácie, štandardné odchýlky a ďalšie
                                            parametre) a zobrazíme v grafickej podobe. Na analyzovanie dát využívame
                                            najmodernejšie softvéry, predovšetkým Tableau, Excel, QuickView či Power BI.
                                            V tomto kroku niekedy zistíme, že je potrebné zozbierať ďalšie dáta.</p>
                                    </Card>
                                }/>
                                <Step status={"process"} title={
                                    <Card hoverable={true} className="card-steps" id="left-card">
                                        <h2><FundFilled id="card-icon"/> Interpretácia výsledkov</h2>
                                        <p style={{color: "var(--mainColor)"}}>Po úspešnej analýze dát Vás kontaktujeme
                                            a interpretujeme Vám zistenia. V prehľadnej grafickej forme Vám
                                            odprezentujeme výsledky, na základe ktorých viete optimalizovať procesy, aby
                                            ste zefektívnili Vaše podnikanie. Poradíme Vám, ako môžete výsledky analýzy
                                            čo najlepšie využiť vo svoj prospech.</p>
                                    </Card>
                                }/>
                            </Steps>
                        </Col>
                    </Row>
                </div>
                <div id="faq" ref={this.faqRef}>
                    <h1>F.A.Q.</h1>
                    <Row className="faq-collapse">
                        <Col span={24}>
                            <Collapse defaultActiveKey={['1']} accordion>
                                <Panel header="Pre koho je vhodná dátová analytika?" key="1">
                                    <p>Dátová analytika sa dá využiť prakticky v každom odvetví. Najčastejšie ju
                                        používajú finančné inštitúcie, marketingové agentúry, priemyselné podniky,
                                        obchodné spoločnosti, logistické firmy, ale aj spoločnosti v mnohých ďalších
                                        trhových sektoroch. Keďže dáta sú všade okolo nás a každý deň s nimi prichádzame
                                        do kontaktu, ich analýza nepochybne pomôže aj Vám.</p>
                                </Panel>
                                <Panel header="Prečo sa oplatí investovať do analýzy dát?" key="2">
                                    <p>Mnohí úspešní manažéri tvrdia, že dáta sú nová ropa. Pre spoločnosti totiž vedia
                                        mať obrovskú hodnotu, ak sú správne analyzované a interpretované. Dátová
                                        analytika Vám umožní vidieť veci, ktoré by ste inak možno nespozorovali. Dokáže
                                        Vám ušetriť desiatky tisíc eur a násobne zvýšiť tržby. Investícia do nej sa Vám
                                        tak po finančnej stránke rozhodne oplatí.</p>
                                </Panel>
                                <Panel header="Ako dokážu dátovú analytiku zužitkovať riaditelia a manažéri?" key="3">
                                    <p>Na základe presnej analýzy dát je možné zvyšovať výkonnosť podniku. Manažérom a
                                        riaditeľom dá dátová analytika komplexný pohľad na jednotlivé sféry biznisu.
                                        Ukáže im efektivitu pracovníkov či nastavených procesov, na základe čoho dokážu
                                        jednoducho zhodnotiť aktuálny stav a vykonať opatrenia na jeho zlepšenie.</p>
                                </Panel>
                                <Panel header="Môžem sa na výsledky analýzy spoľahnúť?" key="4">
                                    <p>V oblasti dátovej analytiky máme dlhoročné skúsenosti. Na základe nich nastavíme
                                        proces zberu a analýzy dát tak, aby sme minimalizovali akékoľvek chyby.
                                        Zozbierané dáta očistíme, overíme ich správnosť a sprístupníme ich pre Vás v
                                        zrozumiteľnej forme. Vy sa tak na ne môžete spoľahnúť a viete ich využiť ako
                                        podklad pri vykonávaní kľúčových rozhodnutí.</p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </div>
                <div id="contact" ref={this.contactRef}>
                    <h1>Kontakt</h1>
                    <Row id="backgroundRow"/>
                    <Row className="faq-collapse" style={{textAlign: "left", marginTop: "50px"}}>
                        <Col xs={{span: 24}} lg={{span: 12}} style={{paddingTop: "50px"}}>
                            <h2 style={{marginBottom: "50px", color: "var(--secondColor)"}}>Kontaktujte
                                nás!</h2>

                            <Row style={{fontSize: "20px"}}>
                                <Col xs={{span: 24}} lg={{span: 12}}>
                                    <p>
                                        E-mail: mail@mail.com<br/>
                                        Telefón: +421 123 456 789 <br/>
                                        <br/>
                                        <br/>
                                        <FacebookFilled/> <a href="https://www.facebook.com/">Facebook</a><br/>
                                        <LinkedinFilled/> <a href="https://www.linkedin.com/">LinkedIn</a>
                                    </p>
                                </Col>
                                <Col xs={{span: 24}} lg={{span: 12}}>
                                    <p>
                                        Aperra s.r.o.<br/>
                                        Odbojárska 640/32<br/>
                                        018 51, Nová Dubnica<br/>
                                        <br/>
                                        IČO: 54404711<br/>
                                        DIČ: 2121688272
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 12}}>
                            <Card style={{width: "auto"}}>
                                <Form
                                    layout="vertical"
                                >
                                    <Form.Item
                                        label={"Meno"}
                                    >
                                        <Input placeholder="Meno"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={"Predmet"}
                                    >
                                        <Input placeholder="Predmet"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={"E-mail"}
                                    >
                                        <Input placeholder="E-mail"/>
                                    </Form.Item>
                                    <Form.Item
                                        label={"Telefón"}
                                    >
                                        <Input placeholder="Telefón"/>
                                    </Form.Item>

                                    <Form.Item
                                        label={"Správa"}
                                    >
                                        <TextArea rows={8} placeholder="Správa"/>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit"
                                                className="login-form-button">
                                            Odoslať
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <BackTop/>
            </div>
        );
    }
}
